import { defineComponent,onMounted, reactive, onUnmounted } from 'vue'
import { Toast } from 'vant';
import { orderCreate, orderPay } from '../../../api'
import { SET_ORDER_INFO } from '@/utils/constant'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import './index.less'

declare let WeixinJSBridge: any;

export default defineComponent({
    setup () {
        const router = useRouter()
        const store = useStore()
        const state: any = reactive({
            money: '',
            data: {}
        })

        onMounted(() => {
            //onAgreement()
            const yl = localStorage.getItem('YL')
            let times = setTimeout(() => {
                clearTimeout(times)
                times = null
                if (yl) {
                    onChongzhi();//调用充值
                }
            }, 400)

            if (store.getters.rechargeMoney !== 0) {
                state.money = store.getters.rechargeMoney
            }
        })

        onUnmounted(() => {
            localStorage.removeItem('YL')
        })

         /**
          * JSAPI调起微信支付
          * @param data 
          */
         const onBridgeReady = (data: any) => {
             //const { data } = state.data
            console.log(data, '看看这里啊。执行了啊。。。。。。。。')
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": data.appId,     //公众号ID，由商户传入     
                "timeStamp": data.timeStamp,     //时间戳，自1970年以来的秒数     
                "nonceStr": data.nonceStr,      //随机串     
                "package": data.packageValue,
                "signType": data.signType,     //微信签名方式：     
                "paySign": data.paySign //微信签名 
            },
            function(res: any) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    router.push({path: '/result'})
                }
            });
        }
        

        /**
         * 生成付款码地址
         * @param {*} id 
         */
        const onGetCode =  async(id: any, openId: any) => {
            const res: any = await orderPay({ 
                transactionId: id, payerId: openId, payType: 1,  tradeType: 'JSAPI'} );
            console.log(res.data, '获取二维码连接')
            localStorage.setItem(`${id}_pay`, JSON.stringify(res.data))
            state.data = res.data
            //debugger
            if (res.data.appId) {
                if (typeof WeixinJSBridge == "undefined") {
                    const doc: any = document
                    if (doc.addEventListener) {
                        doc.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                    } else if (doc.attachEvent) {
                        doc.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                        doc.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                    }
                } else {
                    console.log('11看看这里啊。执行了啊。。。。。。。。')
                    onBridgeReady(res.data);
                    console.log('看看这里啊。执行了啊。。。。。。。。')
                }
            }
        }

        const _orderCreate = async() => {

            let data: any = localStorage.getItem('SET_USER')
            data = JSON.parse(data)
            const accountId = data.user.id;
            const openId = data.user.openId;

            const { money } = state
            const res: any = await orderCreate({data: { id: accountId, name: money }})


            const { id } = res.data
            console.log(res.data, '设置订单获取得数据。。。')
            localStorage.setItem(SET_ORDER_INFO, JSON.stringify({createTime: res.data.createTime, sn: res.data.sn}))
            onGetCode(id, openId);
            console.log(res, '创建订单')
        }

        const onChongzhi =  () => {
            if (!state.money) {
                return Toast('请输入充值金额');
            }
            if (Number(state.money) < Number(12) || Number(state.money) > Number(10000)) {
                return Toast('单笔充值限额12-10000');
            }

            const res = localStorage.getItem('YL')
            if (!res) {
                console.log(store, '2sha....')
                store.commit('setRechargeMoney', state.money)
                router.push({path: '/agreement'})
                return
            }
            console.log('进来了...')
            _orderCreate()
        }

        const onAgreement = () => {
            router.push({path: '/agreement'})
        }

        const onCancel = () => {
            router.push({path: '/amount'})
        }

        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>账户充值</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class='mark-line-left-box'>充值金额</div>
                <div>
                    <input v-model={state.money} class='chongzhi-input' placeholder="单笔充值限额: 12-10000" min="1" max="10000" />
                </div>
                <div class='mark-line-left-box'>充值方式</div>
                <div>
                    <img class='wecha-pay' src='https://img.fanwoon.com/wecha_pay.png' title='' alt='' />
                </div>
                <div>
                    <span class='agree-font-box'>请阅读并同意:</span>
                    <span class='xieyi-font-box' onClick={onAgreement}>《翻腕充值交易说明条款》</span>
                </div>
                <div class='footer-box'>
                    <button class="cancel-btn" onClick={onCancel}>取消</button>
                    <button class="sub-btn" onClick={() => onChongzhi()}>确认充值</button>
                </div>
            </div>
        )
    }
})